.navbar-light .navbar-nav .nav-link {
    color: #008000;
  }
  .nav-container{
    display: flex;
    justify-content: space-around;
    
  }

  .hero {
    color: white;
    text-align: center;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: 
                url('../assets/nairobi.jpg') center/cover no-repeat;
    -webkit-animation: gradientAnimation 40s ease infinite;
    animation: gradientAnimation 40s ease infinite;
}

  
  @-webkit-keyframes gradientAnimation {
    0% { background-position: 0% 30%, center; }
    50% { background-position: 100% 70%, center; }
    100% { background-position: 0% 30%, center; }
  }
  
  @keyframes gradientAnimation {
    0% { background-position: 0% 30%, center; }
    50% { background-position: 100% 70%, center; }
    100% { background-position: 0% 30%, center; }
  }
  
  .hero h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: white;
  }
  
  .hero p {
    font-size: 1.25rem;
    margin-bottom: 2rem;
  }
  
  .hero .learn-more {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    color: #228B22;
    background-color: white; /* Green color */
    opacity: 0.6;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.5s;
  }
  
  .hero .learn-more:hover {
    background-color: #1E7A1E; /* Darker green color */
    color: white;
    transition: background-color 0.5s;
    opacity: 1;
  }

  .who-we-are {
    padding: 3rem 2rem;
    text-align: center;
  }
  
  .who-we-are h2 {
    color: #228B22;
  }
  
  .mission-vision-container {
    display: flex;
    justify-content: center; /* Center the content horizontally */
    align-items: flex-start;
    gap: 2rem;
    flex-wrap: wrap; /* Allow items to wrap on smaller screens */
  }
  
  .mission-vision {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center align items */
    margin-top: 60px;
  }
  
  .mission-vision h3 {
    color: #228B22;
  }
  
  .mission p, .vision p {
    font-size: 17px;
    line-height: 24px;
    font-weight: 400;
    color: rgb(60, 61, 63); /* neutral-900 */
    text-align: center; /* Center align text */
  }
  
  .office-image {
    margin-top: 2rem;
    width: 100%;
    max-height: 50vh;
    object-fit: contain;
  }
  
  .image-container {
    width: 100%;
  }
  
  @media (min-width: 1024px) {
    .mission-vision-container {
      justify-content: space-between; /* Adjust for larger screens */
      align-items: flex-start;
    }
  
    .image-container {
      max-width: 50%; /* Limit the width of the image container */
    }
  
    .mission-vision {
      max-width: 50%; /* Limit the width of the mission-vision container */
      align-items: flex-start; /* Align text to the left */
    }
  }
  
  @media (max-width: 768px) {
    .mission-vision-container {
      flex-direction: column; /* Stack items vertically on smaller screens */
      align-items: center; /* Center align items */
    }
  
    .office-image {
      margin-top: 1rem;
      max-width: 90%; /* Reduce size of the image */
    }
  
    .mission-vision h3, .mission p, .vision p {
      text-align: center; /* Center align text */
    }
  }
  
  @media (max-width: 480px) {
    .who-we-are {
      padding: 2rem 1rem;
    }
  
    .mission-vision-container {
      gap: 1rem;
    }
  
    .mission-vision h3, .mission p, .vision p {
      text-align: center; /* Ensure text is centered on smaller screens */
    }
  }
  


.footer {
  background-color: #008000; /* Dark green color */
  color: white;
  display: flex;
  justify-content: space-around;
  padding: 2rem;
}

.footer h3 {
  margin-bottom: 1rem;
}

.social-media ul {
  list-style: none;
  display: flex;
  gap: 1rem;
}

.social-media a {
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
}
  
  