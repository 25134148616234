.ussd-container {
  display: flex;
  background-color: #ffffff;
  padding-left: 4rem;
  padding-right: 4rem;
  position: relative;
  height: auto;
  align-items: center;
}

.contentSection {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1rem; /* Reduced margin */
}

.mainTitle {
  font-size: 2rem;
  font-weight: bold;
  color: #4CAF50;
  margin-bottom: 0.25rem;
  text-transform: uppercase;
  line-height: 1.2;
}

.subtitle {
  font-size: 1.5rem;
  color: #4CAF50;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  line-height: 1.2;
}

.description {
  font-size: 1rem;
  color: #333;
  margin-bottom: 1rem;
  line-height: 1.4;
}

.ussdCode {
  font-size: 1.2rem;
  color: #fff;
  font-weight: bold;
  background-color: #4CAF50;
  padding: 0.5rem;
  border-radius: 4px;
  max-width: 300px;
  display: inline-block;
  text-align: center;
}

.imageSection {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.personImage {
  max-width: 100%;
  height: auto;
}

.ussd-logo {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  width: 150px;
}

@media (max-width: 768px) {
  .ussd-container {
    flex-direction: column;
    padding: 1rem 0.5rem;
  }

  .contentSection {
    margin-left: 0;
    text-align: center;
  }

  .mainTitle {
    font-size: 1.5rem;
  }

  .subtitle {
    font-size: 1.2rem;
  }

  .description {
    font-size: 0.9rem;
  }

  .ussdCode {
    font-size: 1rem;
  }

  .imageSection {
    margin-top: 1rem;
  }

  .ussd-logo {
    width: 50px;
  }
}