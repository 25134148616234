/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap"); */
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');
html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
  font-family: "Lexend", sans-serif;
}
