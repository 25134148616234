:root {
    --secondary-color: #fff;
    --contrast-color: #008000;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    z-index: -10;
    background-color: var(--contrast-color);
  }
  
  .container {
    display: flex;
    height: 90vh;
    justify-content: space-around;
    align-items: center;
    color: #000000;
    animation: expand .8s ease forwards;
    background-color: var(--secondary-color);
    position: relative;
    transition: all .8s ease;
    margin-top: 50px; /* Adjust according to navbar height */
  }
  
  .container_content {
    width: 50%;
  }
  
  .container_content_inner {
    width: 80%;
    margin-left: 80px;
  }
  
  .container_outer_img {
    margin: 50px;
    width: 50%;
    overflow: hidden;
  }
  
  .container_img {
    width: 100%;
    animation: slideIn 1.5s ease-in-out forwards;
  }
  
  .par {
    height: auto;
    overflow: hidden;
  }
  
  p {
    line-height: 28px;
    transform: translateY(300px);
    animation: slideUp .8s ease-in-out forwards .8s;
  }
  
  .btns {
    height: 100%;
    position: relative;
    width: 150px;
    overflow: hidden;
  }
  
  .btns_more {
    background: transparent;
    border: 1px solid var(--contrast-color);
    border-radius: 50px;
    padding: 8px 12px;
    color: var(--contrast-color);
    font-size: 16px;
    text-transform: uppercase;
    position: relative;
    margin-top: 15px;
    outline: none;
    transform: translateY(50px);
    animation: slideUp .8s ease-in-out forwards 1s;
  }
  
  .title {
    overflow: hidden;
    height: auto;
  }
  
  h1 {
    font-size: 40px;
    color: var(--contrast-color);
    margin-bottom: 20px;
    transform: translateY(100px);
    animation: slideUp .8s ease forwards .5s;
  }
  
  @keyframes slideIn {
    0% {
      transform: translateX(500px) scale(.2);
    }
    100% {
      transform: translateX(0px) scale(1);
    }
  }
  
  @keyframes slideUp {
    0% {
      transform: translateY(300px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  
  @keyframes expand {
    0% {
      transform: translateX(1400px);
    }
    100% {
      transform: translateX(0px);
    }
  }

  .history-container{
margin: 20px;
  }
  .services-container
  {
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 20px;
  }

  h5{
    color: var(--contrast-color);
    transform: translateY(100px);
    animation: slideUp .8s ease forwards .5s;
  }

  .row{
    margin-top: 20px;
  }

  .testimony-container{
    margin-left: 30px;
    margin-right: 30px;
  }
  
  @media (max-width: 768px) {
    .container{
      margin-top: 50px;
    }
    .container h1{
      font-size: 1.2rem;
      margin-top: 200px;
    }
    h5{
      font-size: 1rem;
    }
    .services-container
    {
      margin-top: 150px;
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  